<template>
  <div id="home">
    <cartao></cartao>

  </div>
</template>
<script async>
/* eslint-disable */
export default {
  name: 'Home',
  components: {
    Cartao: () => import('./Cartao.vue'),
  },
  methods: {

  }
}
</script>
<style scoped>
</style>
